<template>
  <div class="profile">
    <user-profile />
  </div>
</template>

<script>
import UserProfile from '@/components/info/UserProfile.vue';

export default {
  name: 'HomeView',
  components: {
    UserProfile
  }
}
</script>

<style lang="less" scoped>
.profile {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
</style>