<template>
  <div class="login-container">
    <div id="login-register">
      <h2>欢迎{{ !showLogin ? '注册' : '登录' }}，让咱们一起致力于“帮到人，赚到钱”的事业吧。</h2>
      <p class="tips">
        {{ showLogin ? '请在下方输入你的用户名和密码以登录，如果没有账号，请转到注册页面。' : '请填写真实信息，不然我们无法联系到您，并与您结算奖金。' }}
      </p>
      <LoginForm v-if="showLogin" />
      <RegisterForm v-else />
      <div class="switch-mode">
        <el-link @click="toggleMode(!showLogin)">切换至{{ showLogin ? '注册' : '登录' }}</el-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import LoginForm from '@/components/auth/Login.vue';
import RegisterForm from '@/components/auth/Register.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';

const route = useRoute();
const showLogin = computed(() => route.path === '/login');

const toggleMode = (mode) => {
  if (!mode) {
    router.push('/register');
  } else {
    router.push('/login');
  }
};
</script>

<style lang="less" scoped>
.login-container {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

#login-register {
  width: 100%;
  max-width: 600px;
  background: rgba(255, 255, 255, .8);
  padding: 30px 12px;

  h2 {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 20px;
  }

  .tips {
    font-size: 12px;
    margin-bottom: 60px;
    color: darksalmon;
  }
}

.switch-mode {
  text-align: center;
  margin-top: 20px;
  a {
    color: #409EFF;
  }
}
</style>