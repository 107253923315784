import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue';
import LoginView from '@/views/LoginView.vue';
import AddClientView from '@/views/AddClientView.vue';
import MineView from '@/views/MineView.vue';
import GuideView from '@/views/GuideView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: LoginView
  },
  {
    path: '/client/add',
    name: 'add-client',
    component: AddClientView
  },
  {
    path: '/mine',
    name: 'mine',
    component: MineView
  },
  {
    path: '/guide',
    name: 'guide',
    component: GuideView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
