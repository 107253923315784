<template>
  <div class="add">
    <add-client />
  </div>
</template>

<script>
import AddClient from '@/components/client/AddClient.vue';
import router from '@/router';

export default {
  name: 'HomeView',
  components: {
    AddClient
  }
}
</script>

<style lang="less" scoped>
.add {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  .page-header {
    width: 100%;
    padding: 30px 0;
  }
}
</style>
