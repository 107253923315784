<template>
  <div class="home">
    <basic-info />
  </div>
</template>

<script>
import BasicInfo from '@/components/info/BasicInfoPanel.vue';

export default {
  name: 'HomeView',
  components: {
    BasicInfo,
  }
}
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding: 10px;
}
</style>
