<template>
  <div class="basic-info">
    <div class="income">
      <div class="box">
        <div><span>待结算收入:</span><p>{{ pendingBonus }} 元</p></div>
        <div><span>已结算收入:</span><p>{{ paidBonus }} 元</p></div>
      </div>
      <div class="sum-income"><strong>总收入:</strong><p>{{ totalBonus }} 元</p></div>
    </div>
    <p v-if="hasActiveSessions" class="tips">有正在进行的会话，可能产生潜在收入。</p>
    <div class="table-box">
      <div class="table-btn-box">
        <el-button type="primary" size="small" @click="jumpToAddClient">添加新客户</el-button>
      </div>
      <el-table 
        v-loading="loading" 
        :data="clients" 
        style="width: 100%" 
        stripe 
        show-overflow-tooltip
        empty-text="还未添加客户"
      >
        <el-table-column prop="name" label="姓名" />
        <el-table-column prop="contact" label="联系方式" />
        <el-table-column prop="created_at" label="创建时间" />
      </el-table>
      <el-pagination
        v-if="totalPages > 1"
        layout="prev, pager, next"
        :total="totalPages"
        :current-page="currentPage"
        @current-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { ElTable, ElTableColumn, ElPagination, ElMessage } from 'element-plus';
import { request } from '@/utils/apiClient';
import router from '@/router/index';

const pendingBonus = ref(0);
const paidBonus = ref(0);
const totalBonus = ref(0);
const hasActiveSessions = ref(false);
const clients = ref([]);
const loading = ref(true);
const currentPage = ref(1);
const totalPages = ref(1);

const handlePageChange = async (val) => {
  currentPage.value = val;
  await fetchClients();
};

const jumpToAddClient = () => {
  router.push('/client/add');
}

const fetchBonusData = async () => {
  try {
    const response = await request('api/client/get_bonus/', 'get');
    if (response) {
      pendingBonus.value = response?.pending_bonus;
      paidBonus.value = response?.paid_bonus;
      totalBonus.value = response?.total_bonus;
    }
  } catch (error) {
    ElMessage.error('获取奖金数据失败');
  }
};

const checkSessions = async () => {
  try {
    const response = await request('api/client/check_sessions_by_current_referrer/', 'get');
    if (response?.all_sessions_completed_or_none === false) {
      hasActiveSessions.value = true;
    }
  } catch (error) {
    ElMessage.error('检查会话状态失败');
  }
};

const fetchClients = async () => {
  try {
    const params = { page: currentPage.value };
    const response = await request('api/client/my_clients/', 'get', params);
    if (response) {
      const clientsList = response?.clients ?? [];
      clients.value = clientsList.map(client => {
        const contacts = [client.phone_number, client.wechat, client.email]
        return {
          ...client,
          contact: contacts.filter(item => item !== "").join(' | ')
        }
      })
      totalPages.value = response.total_pages;
    }
  } catch (error) {
    ElMessage.error('获取客户列表失败');
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await Promise.all([fetchBonusData(), checkSessions(), fetchClients()]);
});
</script>

<style lang="less" scoped>
.basic-info {
  margin-top: 20px;
  max-width: 600px;
  width: 100%;
}

.income {
  margin-bottom: 40px;
  margin-top: 30px;
  background: rgba(255, 255, 255, .8);

  .box {
    display: flex;

    div {
      display: flex;
      flex: 1;
      flex-direction: column;
      
      span {
        font-size: 12px;
        color: burlywood;
      }

      p {
        font-size: 22px;
        line-height: 56px;
        color: #606266;
        text-align: left;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
      }
    }
  }
  .sum-income {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 180px;

    p {
      font-size: 52px;
      text-align: center;
      color: orange;
      font-weight: 600;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
    }

    strong {
      font-size: 12px;
      line-height: 32px;
      color: red;
      text-align: center;
    }
  }
}

.tips {
  font-size: 14px;
  color: burlywood;
  margin-bottom: 50px;
}

.table-box {
  width: 100%;
  display: flex;
  flex-direction: column;

  .table-btn-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
</style>