<template>
  <div class="out-container-app">
    <div class="bar">
      <img v-if="currentPath === '/' || 
        currentPath === '/mine' || 
        currentPath === '/login' || 
        currentPath === '/register' ||
        currentPath === '/guide'" src="./assets/logo.png" alt="logo" class="logo" />
      <div v-else class="page-header">
        <el-page-header @back="goBack" title="返回">
        </el-page-header>
      </div>
      <div class="status-box">
        <router-link v-if="user?.username" class="link" to="/">看板</router-link>
        <router-link v-if="user?.username" class="link" to="/mine">我的</router-link>
        <router-link v-if="user?.username" class="link" to="/guide">联络人指南</router-link>
        <el-button v-if="user?.username" size="small" type="danger" @click="logout">登出</el-button>
        <el-button v-else size="small" type="primary" @click="login">登录</el-button>
      </div>
    </div>
    <div class="router-box">
      <router-view />
    </div>
  </div>
</template>

<style lang="less" scoped>
.out-container-app {
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/back-app.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}

.bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 36px;
  height: 60px;
  background-color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.logo {
  width: 36px;
  height: 36px;
}

.link {
  text-decoration: none;
  color: #606266;
  margin-right: 30px;
  font-size: 14px;
  font-weight: 600;
}

.router-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  width: 100%;
}
</style>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import router from '@/router'
import { useRoute } from 'vue-router';

export default {
  setup() {
    const store = useStore();

    const user = computed(() => store.getters.getUser);

    const route = useRoute();

    const currentPath = computed(() => route.path)

    const login = async () => {
      router.push('/login');
    };

    const logout = () => {
      store.dispatch('logout');
    };

    store.dispatch('fetchUser')

    const goBack = () => {
      router.go(-1)
    }

    return { user, login, logout, currentPath, goBack };
  },
};
</script>
