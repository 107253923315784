<template>
  <div class="out-box">
    <el-form v-model="client" ref="clientForm" label-width="auto" label-position="top" :model="client" :rules="addRules">
      <el-form-item 
        label="姓名"
        prop="name"
        :rules="[
          { required: true, message: '请告诉我们他/她叫什么吧', trigger: 'blur' },
        ]"
      >
        <el-input v-model="client.name"></el-input>
      </el-form-item>
      <el-form-item 
        label="邮箱"
        prop="email"
        :rules="[
          {
            type: 'email',
            message: '请输入正确的邮箱格式',
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <el-input v-model="client.email"></el-input>
      </el-form-item>
      <el-form-item 
        label="电话号码"
        prop="phone_number"
      >
        <el-input v-model="client.phone_number"></el-input>
      </el-form-item>
      <el-form-item label="微信号（不是昵称）">
        <el-input v-model="client.wechat"></el-input>
      </el-form-item>
      <el-form-item label="地址">
        <el-input v-model="client.address"></el-input>
      </el-form-item>
      <el-form-item label="生日">
        <el-date-picker 
          v-model="client.birthday" 
          :editable="false" 
          :clearable="false" 
          type="date"
          value-format="YYYY-MM-DD"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="client.gender">
          <el-radio label="M">男</el-radio>
          <el-radio label="F">女</el-radio>
          <el-radio label="O">其他</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="客户有什么需求呢？">
        <el-input
          v-model="client.intro"
          :autosize="{ minRows: 4, maxRows: 10 }"
          type="textarea"
          placeholder="简单介绍一下客户的情况及需求"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="createClient">添加新客户</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';
import router from '@/router';
import { request } from '@/utils/apiClient';

const addRules = {};

const client = ref({
  name: '',
  email: '',
  phone_number: '',
  wechat: '',
  address: '',
  birthday: '1900-01-01',
  gender: 'O',
  notes: '',
});

const route = useRoute();

function isValidChinesePhoneNumber(phoneNumber) {
    // 正则表达式匹配中国手机号码
    const phonePattern = /^1[3-9]\d{9}$/;
    return phonePattern.test(phoneNumber);
}

function isValidEmail(email) {
    // 使用正则表达式来匹配常见的电子邮件格式
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}

async function createClient() {
  if (!client.value.name) {
    ElMessage.warning('请填写客户姓名');
    return;
  }

  const email = client.value?.email;
  const phone_number = client.value?.phone_number;
  const wechat = client.value?.wechat;

  if (!email && !phone_number && !wechat) {
    ElMessage.warning('请填写必要信息，微信号\手机\邮箱之一必须填写');
    return;
  }

  if (email) {
    const isValid = isValidEmail(email);
    if (!isValid) {
      ElMessage.warning('请输入正确的邮件地址')
      return
    }
  }

  if (phone_number) {
    const isValid = isValidChinesePhoneNumber(phone_number);
    if (!isValid) {
      ElMessage.warning('请输入正确的电话号码')
      return
    }
  }

  try {
    const response = await request('api/client/create_client/', 'post', {}, client.value);
    ElMessage.success('客户创建成功');
    // 可以选择重置表单或跳转页面等操作
    client.value = {}; // 重置表单
    router.go(-1);

  } catch (error) {
    ElMessage.error('创建客户时发生错误: ' + error.response.data.error);
  }
}
</script>

<style lang="less" scoped>
.out-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background: rgba(255, 255, 255, .8);
  max-width: 600px;
}
</style>
