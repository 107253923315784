<template>
  <div class="user-profile">
    <el-form ref="form" :model="userProfile" label-width="80px">
      <el-form-item label="用户名">
        <el-input v-model="userProfile.username" disabled></el-input>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="userProfile.email"></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="userProfile.name"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-select v-model="userProfile.gender" placeholder="请选择性别">
          <el-option v-for="item in genderOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="微信号">
        <el-input v-model="userProfile.wechat"></el-input>
      </el-form-item>
      <el-form-item label="地址">
        <el-input v-model="userProfile.address"></el-input>
      </el-form-item>
      <el-form-item label="开户行">
        <el-input v-model="userProfile.bank"></el-input>
      </el-form-item>
      <el-form-item label="银行账号">
        <el-input v-model="userProfile.account_number"></el-input>
      </el-form-item>
      <el-form-item label="出生日期">
        <el-date-picker
          v-model="userProfile.birth_date"
          type="date"
          :editable="false"
          :clearable="false"
          placeholder="选择日期"
          value-format="YYYY-MM-DD"
        ></el-date-picker>
        </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="updateProfile">更新我的资料</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { request } from '@/utils/apiClient';
import { ElMessage, ElSelect, ElOption } from 'element-plus';

const userProfile = ref({});
const genderOptions = ref([
  { value: 'M', label: '男' },
  { value: 'F', label: '女' },
]);

onMounted(async () => {
  try {
    const response = await request('api/client/user/profile/');
    userProfile.value = response;
  } catch (error) {
    ElMessage.error('获取用户信息失败');
  }
});

async function updateProfile() {
  try {
    const postData = {
      ...userProfile.value,
      birth_date: userProfile.value.birth_date || '1900-01-01', // 确保与后端字段匹配
    };

    await request('api/client/update_user_profile/', 'post', {}, postData);
    ElMessage.success('资料更新成功');
  } catch (error) {
    ElMessage.error('更新资料时发生错误');
  }
}
</script>

<style lang="less" scoped>
.user-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background: rgba(255, 255, 255, .8);
  max-width: 600px;
}
</style>
