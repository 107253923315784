import { createStore } from 'vuex';
import { request } from '@/utils/apiClient';
import router from '@/router';

export default createStore({
  state: {
    user: null,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    async fetchUser({ commit }, userId) {
      const userData = await fetchUserData(userId);
      commit('setUser', userData);
    },
    async logout({ commit }) {
      try {
        await logout();
      } catch(err) {
        console.error(err)
      } finally {
        commit('clearUser');
        router.push('/login');
      }
    },
  },
});

async function fetchUserData() {
  try {
    const resopnse = await request('api/client/user/profile/', 'get')
    return resopnse
  } catch (err) {
    console.error(err)
    return {}
  }
}

async function logout() {
  try {
    const resopnse = await request('api/client/logout/', 'put')
    return resopnse
  } catch (err) {
    console.error(err)
    return {}
  }
}