import router from '@/router';
import axios from 'axios';

const BASE_URL = 'https://platform.myquantums.org'
// const BASE_URL = 'http://localhost:8000'


export const request = async (url, method = 'get', params = {}, data = {}, headers = {}) => {
  if (!url) {
    throw new Error('url cannot be an empty string!');
  }

  const methods = ['get', 'post', 'put', 'delete', 'patch'];
  if (!methods.includes(method.toLowerCase())) {
    throw new Error('Invalid HTTP method');
  }

  const axiosMethod = axios;
  if (!axiosMethod) {
    throw new Error('Unsupported HTTP method');
  }

  const fromData = new URLSearchParams()
  Object.keys(data).forEach(key => {
    fromData.append(key, data[key])
  })

  try {
    const response = await axiosMethod({
      url: `${BASE_URL}/${url}`,
      method,
      params,
      data: fromData,
      headers: headers,
      withCredentials: true
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized request, token might have expired.');
      localStorage.removeItem('token');
      router.push('/login');
    } else {
      console.error('Request failed:', error.response?.data || error.message);
    }

    throw error; // 重新抛出错误，以便调用者可以处理
  }
};
