<template>
  <el-form ref="registerForm" :model="registerData" :rules="registerRules" label-width="80px">
    <el-form-item label="用户名" prop="username" required>
      <el-input v-model="registerData.username" placeholder="请输入用户名" />
    </el-form-item>
    <el-form-item label="邮箱" prop="email" required>
      <el-input v-model="registerData.email" placeholder="请输入邮箱" />
    </el-form-item>
    <el-form-item label="密码" prop="password" required>
      <el-input type="password" v-model="registerData.password" placeholder="请输入密码" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="handleRegister">注册</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref } from 'vue';
import { request } from '@/utils/apiClient';
import { ElForm, ElFormItem, ElInput, ElButton, ElMessage } from 'element-plus';
import router from '@/router';

const registerData = ref({
  username: '',
  email: '',
  password: '',
});

const registerRules = {
  username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
  email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }, { type: 'email', message: '邮箱格式不正确', trigger: ['blur', 'change'] }],
  password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
};

const handleRegister = async () => {
  if (!registerData.value.email || !registerData.value.password || !registerData.value.username) {
    ElMessage.info('请完整填写信息。')
    return;
  }
  
  try {
    const response = await request('api/client/register_user/', 'post', {}, registerData.value);
    if (response) {
      ElMessage.success('注册成功');
      router.push('/login');
    } else {
      ElMessage.error(response.message || '注册失败');
    }
  } catch (error) {
    console.error('注册错误:', error);
    ElMessage.error('注册时发生错误');
  }
};
</script>