<template>
  <el-form ref="loginForm" :model="loginData" :rules="loginRules" label-width="80px">
    <el-form-item label="用户名" prop="username" required>
      <el-input v-model="loginData.username" placeholder="请输入用户名" />
    </el-form-item>
    <el-form-item label="密码" prop="password" required>
      <el-input type="password" v-model="loginData.password" placeholder="请输入密码" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="handleLogin">登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref } from 'vue';
import { request } from '@/utils/apiClient';
import { ElForm, ElFormItem, ElInput, ElButton, ElMessage } from 'element-plus';
import router from '@/router'
import store from '@/store';

const loginData = ref({
  username: '',
  password: '',
});

const loginRules = {
  username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
  password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
};

const handleLogin = async () => {
  try {
    const response = await request('api/client/login/', 'post', {}, loginData.value);
    if (response) {
      ElMessage.success('登录成功');
      store.dispatch('fetchUser') 
      router.push('/');
    } else {
      ElMessage.error(response.message || '登录失败');
    }
  } catch (error) {
    console.error('登录错误:', error);
    ElMessage.error('登录时发生错误，请检查用户名和密码是否正确');
  }
};
</script>