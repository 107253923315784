<template>
  <div class="contact-guide">
    <div class="guide-container">
      <el-row :gutter="20">
        <el-col :span="16" :offset="1">
          <h1 class="title">联络人指南</h1>
        </el-col>
      </el-row>

      <el-row class="row" :gutter="20">
        <el-col :span="16" :offset="1">
          <p class="step-title">第1步：</p>
          <p class="step-details">如果您身边有需要购买保险或进行风险评估、资产配置的人士，请将我们的联系方式告知他们。</p>
          <img src="../assets/联络人发给潜在客户的海报-20240628.jpg" style="width: 100%;"/>
        </el-col>
      </el-row>

      <el-row class="row" :gutter="20">
        <el-col :span="16" :offset="1">
          <p class="step-title">第2步：</p>
          <p class="step-details">请将他们的姓名、电话号码以及电子邮件地址发送给我们，以便我们可以与他们取得联系。</p>
          <el-button type="primary" @click="handleClick">立即参与（登记联系人信息）</el-button>
        </el-col>
      </el-row>

      <el-row class="row" :gutter="20">
        <el-col :span="16" :offset="1">
          <p class="step-title">第3步：</p>
          <p class="step-details">我们将根据他们的需求提供相应的服务，并向您反馈进展情况、结算奖金。；奖金数额一般为投保金额的5%-12%；只要此用户后续有投保或其他业务我们都会为您择情发放奖金。</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import router from '@/router';

export default {
  methods: {
    handleClick() {
      router.push('/client/add');
    },
  },
};
</script>

<style scoped lang="less">
.contact-guide {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  .guide-container {
    width: 100%;
    max-width: 600px;
  }
}

.title {
  font-size: 24px;
  margin-bottom: 40px;
  margin-top: 50px;
}

.row {
  background-color: aliceblue;
  border-radius: 10px;
  margin: 10px 0;
  padding-bottom: 20px;
}

.step-title {
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.step-details {
  line-height: 1.75;
  text-align: justify;
  margin-bottom: 20px;
  font-size: 14px;
}
</style>